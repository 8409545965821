<template>
    <div>


        <b-overlay :show="show" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">


            <!-- <div class="ml-3 mt-4">
                เงื่อนไข
                <b-button class="ml-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                    @click="selectdate(0)">วันนี้
                </b-button>
                <b-button class="ml-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                    @click="selectdate(1)">เมื่อวาน
                </b-button>
                <b-button class="ml-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                    @click="selectdate(2)">7 วันที่ผ่านมา
                </b-button>
                <b-button class="ml-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                    @click="selectdate(3)">30 วันที่ผ่านมา
                </b-button>
                <b-button class="ml-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                    @click="selectdate(4)">เดือนนี้
                </b-button>
                <b-button class="ml-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                    @click="selectdate(6)"> 1 ปีย้อนหลัง
                </b-button>
                <b-button class="ml-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                    @click="selectdate(5)">กำหนดเอง
                </b-button>
                <br>
                <br>
                <div class="p-1"
                    style="background-color:#18f0fb; color: #fff; font-weight: bold; text-align: center; border-radius: 10px; font-size:20px">
                    {{ datelabel }}
                </div>
                <b-form-group v-if="customdate">
                    <h5>ระบุช่วงวันที่</h5>
                    <flat-pickr v-model="rangeDate" class="form-control" :config="{ mode: 'range' }"
                        @input="onDateChange" />
                </b-form-group>

            </div> -->




            <div class="m-3" align="center">

                <b-row>
                    <b-col cols="12">
                        <div align="center" class="mb-2">
                            <h2>{{ title }}</h2>

                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="3" class="p-1" style=" border: 1.3px solid #000000;">
                        <h4>Gateway</h4>
                        <hr />
                        <b-row>
                            <b-col cols="6" style="background-color:green;color:#fff"> Online: {{ gateway_online }} </b-col>
                            <b-col cols="6" style="background-color:red;color:#fff"> Offline: {{ gateway_offline }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12"> <br><b>Total: {{ gateway_all }}</b> </b-col>
                        </b-row>

                    </b-col>
                    <b-col cols="4" class="p-1" style=" border: 1.3px solid #000000;">
                        <h4>AP</h4>
                        <hr />
                        <b-row>
                            <b-col class="justify-content-center align-items-center" cols="4"
                                style="background-color:#fbff00;color:#000000; font-size:13px">วันที่ดึงข้อมูลได้ครั้งสุดท้าย</b-col>
                            <b-col class="justify-content-center align-items-center" cols="4"
                                style="background-color:green;color:#fff">Online: {{ ap_online }}</b-col>
                            <b-col class="justify-content-center align-items-center" cols="4"
                                style="background-color:red;color:#fff">Offline: {{ ap_offline }}</b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="4"><br> <b>{{ ap_last_date }}</b> </b-col>
                            <b-col cols="4"><br><b>unknow: {{ ap_unknown }}</b> </b-col>
                            <b-col cols="4"><br><b>Total: {{ ap_all }}</b> </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="5">
                        <b-row>
                            <b-col cols="2" style="border: 1.3px solid #000000;">
                                <h5 class="p-1">
                                    <br>
                                    session
                                    <hr />
                                    <b>Total: {{ session_all }}</b>
                                </h5>



                            </b-col>
                            <b-col cols="2" style="font-size:12px; border: 1.3px solid #000000;">
                                <h5 class="p-1"> ค่าเฉลี่ย session ต่อ AP
                                    <hr />
                                    <b> {{ Average_session_per_AP }}</b>
                                </h5>
                            </b-col>
                            <b-col cols="3" style="font-size:12px; border: 1.3px solid #000000;">
                                <h5 class="p-1">ค่าเฉลี่ย session ต่อ AP ต่อเดือน
                                    <hr />
                                    <b>{{ Average_session_per_AP_per_month }}</b>
                                    <hr />
                                    <b>{{ months }} เดือน</b>
                                    <br>
                                    <b style="font-size:7px">{{ months_text }}</b>
                                </h5>
                            </b-col>
                            <b-col cols="3" style="font-size:12px; border: 1.3px solid #000000;">
                                <h5 class="p-1">ราคาที่ต้องจ่ายต่อเดือน
                                    <hr />
                                    <b>{{ price }}</b>
                                </h5>
                            </b-col>
                            <b-col cols="2" style="font-size:12px; border: 1.3px solid #000000;">
                                <h5 class="p-1">ราคาที่ต้องจ่ายทั้งหมด
                                    <hr />
                                    <b>{{price*Average_session_per_AP_per_month}}</b>
                                </h5>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <br />
                <hr>
                </hr>
                <b-row>
                    <b-col cols="10">
                        <div align="center" class="mb-2" style="background-color:#18F4FB;">
                            <h2>Mikrotik</h2>
                        </div>

                        <b-row>
                            <b-col cols="2"><b>mac</b></b-col>
                            <b-col cols="2"><b>description</b></b-col>
                            <b-col cols="2"><b>วันลงทะเบียนอุปกรณ์ในระบบ serve</b></b-col>
                            <b-col cols="1"><b>เชื่อมต่อครั้งแรก</b></b-col>
                            <b-col cols="1"><b>เชื่อมต่อครั้งล่าสุด</b></b-col>
                            <b-col cols="1"><b>ตอบกลับ serve ล่าสุด</b></b-col>
                            <b-col cols="1"><b>diffInDays</b></b-col>
                            <b-col cols="1"><b>status</b></b-col>
                        </b-row>

                        <b-row v-for="(gw, index) in gateway">
                            <b-col cols="2" style="font-size:12px"><b>{{ gw[0] }}</b></b-col>
                            <b-col cols="2" style="font-size:12px">{{ gw[1] }}</b-col>
                            <b-col cols="2" style="font-size:12px">{{ gw[2] }}5</b-col>
                            <b-col cols="1" style="font-size:12px">{{ gw[3] }}</b-col>
                            <b-col cols="1" style="font-size:12px">{{ gw[4] }}</b-col>
                            <b-col cols="1" style="font-size:12px">{{ gw[5] }}</b-col>
                            <b-col cols="1">{{ gw[6] }}</b-col>
                            <b-col cols="1">{{ gw[7] }}</b-col>
                        </b-row>


                    </b-col>

                    <b-col cols="2">
                        <div align="center" class="mb-2" style="background-color:#FBE018;">
                            <h2>AP</h2>
                        </div>


                        <b-row>
                            <b-col cols="7"><b>mac</b></b-col>

                            <b-col cols="2"><b>status</b></b-col>
                        </b-row>

                        <b-row v-for="(a, index) in ap">
                            <b-col cols="7"><b>{{ a[0] }}</b></b-col>

                            <b-col cols="2"><b>{{a[1]}}</b></b-col>
                        </b-row>
                 
                    </b-col>

                </b-row>

            </div>
            <template #overlay>
                <div class="text-center p-3 bg-primary text-light rounded">

                    <div class="mb-2">
                        <h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="4rem" height="4rem" viewBox="0 0 24 24">
                                <circle cx="12" cy="2" r="0" fill="currentColor">
                                    <animate attributeName="r" begin="0" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(45 12 12)">
                                    <animate attributeName="r" begin="0.125s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(90 12 12)">
                                    <animate attributeName="r" begin="0.25s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(135 12 12)">
                                    <animate attributeName="r" begin="0.375s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(180 12 12)">
                                    <animate attributeName="r" begin="0.5s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(225 12 12)">
                                    <animate attributeName="r" begin="0.625s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(270 12 12)">
                                    <animate attributeName="r" begin="0.75s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                                <circle cx="12" cy="2" r="0" fill="currentColor" transform="rotate(315 12 12)">
                                    <animate attributeName="r" begin="0.875s" calcMode="spline" dur="1s"
                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                        repeatCount="indefinite" values="0;2;0;0" />
                                </circle>
                            </svg>

                            <br>
                            <br>
                            กำลังโหลด...
                        </h3>
                    </div>

                </div>

            </template>

        </b-overlay>

    </div>
</template>
  
<script>
import {
    BRow, BCol, BOverlay,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BTable,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormSelect,
} from "bootstrap-vue";
import api from "@/api";
import flatPickr from "vue-flatpickr-component";
import Ripple from 'vue-ripple-directive'

export default {
    directives: {
        Ripple,
    },
    components: {
        flatPickr,
        BRow,
        BCol,
        BOverlay,
        BDropdown,
        BDropdownItem,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BCardText,
        BTable,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BFormGroup,
        BFormSelect,
    },
    data() {
        return {
            show: true,
            title: '',
            items: [],
            customdate: false,
            datelabel: "7 วันที่ผ่านมา",
            rangeDate: null,
            daterange: 2,

            gateway: [],
            gateway_all: 0,
            gateway_offline: 0,
            gateway_online: 0,
            gateway_total: 0,
            session_all: 0,
            ap_last_date: 'ไม่พบ',
            ap: [],
            ap_online: 0,
            ap_offline: 0,
            ap_unknown: 0,
            ap_all: 0,
            Average_session_per_AP: 0,
            Average_session_per_AP_per_month: 0,
            months: 0,
            months_text: '',
            price: 0,


        }
    },
    beforeMount() {
        this.selectdate(this.daterange);
        console.log('this.daterange :>> ', this.daterange);
    },

    mounted() {
        this.title = localStorage.getItem('venue_title')


    },
    methods: {
        loadData() {
            this.show = true
            const params = {
                id: localStorage.getItem("venue_id"),
                fromDate: this.fromDate,
                toDate: this.toDate,
            };
            api
                .post("/reports1", params)
                .then((response) => {
                    console.log(response.data);

                    this.items = response.data;

                    this.gateway = response.data.gateway
                    this.gateway_all = response.data.gateway_all
                    this.gateway_offline = response.data.gateway_offline
                    this.gateway_online = response.data.gateway_online
                    this.gateway_total = response.data.gateway_total
                    this.session_all = response.data.session_all
                    this.ap_last_date = response.data.ap_last_date
                    this.ap = response.data.ap
                    this.ap_online = response.data.ap_online
                    this.ap_offline = response.data.ap_offline
                    this.ap_unknown = response.data.ap_unknown
                    this.ap_all = response.data.ap_all
                    this.Average_session_per_AP = response.data.Average_session_per_AP
                    this.Average_session_per_AP_per_month = response.data.Average_session_per_AP_per_month
                    this.months_text = response.data.months_text
                    this.months = response.data.months
                    this.price = response.data.price
                    this.show = false
                    console.log('this.gateway :>> ', this.gateway);
                })
                .catch((error) => {
                });


        },

        selectdate(daterange) {

            this.daterange = daterange;
            // alert(daterange);
            var today = this.$moment();

            //  console.log(daterange);
            if (daterange == 0) {
                this.customdate = false;
                this.datelabel = "วันนี้";
                this.fromDate = today.format("YYYY-MM-DD");
                this.toDate = today.format("YYYY-MM-DD");
                this.$nextTick(() => {
                    this.loadData();
                });
            } else if (daterange == 1) {
                this.customdate = false;
                this.datelabel = "เมื่อวาน";

                this.fromDate = today.subtract(1, "days").format("YYYY-MM-DD");
                this.toDate = this.fromDate;
                this.$nextTick(() => {
                    this.loadData();
                });

                console.log(this.fromDate);
                console.log(this.toDate);
            } else if (daterange == 2) {
                this.datelabel = "7 วันที่ผ่านมา";

                this.fromDate = today.subtract(7, "days").format("YYYY-MM-DD");
                this.toDate = this.$moment().format("YYYY-MM-DD");
                this.$nextTick(() => {
                    this.loadData();
                });

                console.log(this.fromDate);
                console.log(this.toDate);
            } else if (daterange == 3) {
                this.customdate = false;

                this.datelabel = "30 วันที่ผ่านมา";

                this.fromDate = today.subtract(30, "days").format("YYYY-MM-DD");
                this.toDate = this.$moment().format("YYYY-MM-DD");
                this.$nextTick(() => {
                    this.loadData();
                });

                console.log(this.fromDate);
                console.log(this.toDate);
            } else if (daterange == 4) {
                this.customdate = false;

                this.datelabel = "เดือนนี้";

                this.fromDate = this.$moment().startOf("month").format("YYYY-MM-DD");
                this.toDate = this.$moment().endOf("month").format("YYYY-MM-DD");
                this.$nextTick(() => {
                    this.loadData();
                });
            } else if (daterange == 5) {
                this.customdate = true;
                this.datelabel = "กำหนดเอง";

                this.fromDate = null;
                this.toDate = null;

                console.log(this.fromDate);
                console.log(this.toDate);
            } else if (daterange == 6) {
                this.customdate = false;

                this.datelabel = "1 ปีย้อนหลัง";

                this.fromDate = today.subtract(1, "year").format("YYYY-MM-DD");
                this.toDate = this.$moment().format("YYYY-MM-DD");
                this.$nextTick(() => {
                    this.loadData();
                });

                console.log(this.fromDate);
                console.log(this.toDate);
            }

            else {
                this.fromDate = null;
                this.toDate = null;
            }

            // console.log(currentDateWithFormat);
        },
        onDateChange() {
            console.log(this.rangeDate);
            if (this.rangeDate.search("to") > 0) {
                const myArr = this.rangeDate.split(" to ");
                this.fromDate = myArr[0];
                this.toDate = myArr[1];
                this.$nextTick(() => {
                    this.loadData();
                });
            }
        },

    }
};
</script>
  
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  